/**
 * @ngdoc overview
 * @name simulateurFrontApp
 * @description
 * # simulateurFrontApp
 *
 * Main module of the application.
 */
angular
    .module('simulateurFrontApp')
    .constant('ApiEndPoint', {
        url: 'https://jezequel-api-prod.zi-apps.fr',
        // url: 'http://localhost:1337',
    })
