/**
 * @ngdoc overview
 * @name simulateurFrontApp
 * @description
 * # simulateurFrontApp
 *
 * Main module of the application.
 */
angular
    .module('simulateurFrontApp', [
        'ngAnimate',
        'ngCookies',
        'ngResource',
        'ngRoute',
        'ngSanitize',
        'ngTouch',
        'moment-picker'
    ])
    .config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/', {
                template: require('../views/main.html'),
                controller: 'MainCtrl',
                controllerAs: 'main'
            })
            .otherwise({
                redirectTo: '/'
            });
    }])
    .config(['momentPickerProvider', function (momentPickerProvider) {
        momentPickerProvider.options({
            /* Picker properties */
            locale: 'fr',
            format: 'DD-MM-YYYY',
            minView: 'decade',
            maxView: 'day',
            startView: 'year',
            autoclose: true,
            today: true,
            keyboard: false,

            /* Extra: Views properties */
            leftArrow: '&larr;',
            rightArrow: '&rarr;',
            yearsFormat: 'YYYY',
            monthsFormat: 'MMM',
            daysFormat: 'D',
        });
    }]);
