/**
 * @ngdoc overview
 * @name simulateurFrontApp
 * @description
 * # simulateurFrontApp
 *
 * Main module of the application.
 */
angular
    .module('simulateurFrontApp')
    .constant('Messages', {
        "NOT_ENOUGH_WEIGHT": {
            "title": "Nombre de meubles insuffisant",
            "message": "Veuillez sélectionner plus de meubles"
        },
        "SENDING_SUCCESS": {
            "title": "Votre demande a bien été envoyée",
            "message": "Nous allons vous recontacter très prochainement"
        },
        "SENDING_FAILED": {
            "title": "Une erreur est survenue",
            "message": "Votre demande n'a pu aboutir. Veuillez réessayer"
        }
    })
