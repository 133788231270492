import process from 'process';

/**
 * @ngdoc function
 * @name simulateurFrontApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the simulateurFrontApp
 */
angular.module('simulateurFrontApp')
    .controller('MainCtrl', ['ApiEndPoint', '$scope', '$http', '$timeout', '$rootScope', '$window', 'Messages', 'Prestations', function (ApiEndPoint, $scope, $http, $timeout, $rootScope, $window, Messages, Prestations) {
        $scope.logoUrl = process.env.logoUrl;
        $scope.returnUrl = process.env.website;
        $scope.guides = process.env.guides;

        $scope.currentCategory;
        $scope.categories;
        $scope.step = 1;
        $scope.total = 0;

        $scope.prestations = Prestations;

        $scope.selectedPack = 'none';

        $scope.packs = [
            {
                title: "Date de déménagement à votre convenance",
                subtitle: "Selon disponibilités de nos équipes",
                statuses: ["non-inclus", "compris", "compris", "compris"]
            },
            {
                title: "Délais de 3 à 4 semaines",
                subtitle: "Accordé par le client",
                statuses: ["compris", "non-inclus", "non-inclus", "non-inclus"]
            },
            {
                title: "Chargement - Transport - Livraison",
                subtitle: "Par nos compagnons - déménageurs en fourgon capitonné sur coussind d'air",
                statuses: ["compris", "compris", "compris", "compris"]
            },
            {
                title: "Fourniture d'emballage pour déménagement",
                subtitle: "Carton de déménagement, housses, ruban adhésif, etc",
                statuses: ["option", "option", "compris", "compris"]
            },
            {
                title: "Protection du mobilier",
                subtitle: "Sous couverture, mise sous housse de protection des matelas et sommiers",
                statuses: ["compris", "compris", "compris", "compris"]
            },
            {
                title: "Mise en penderie des vêtements",
                subtitle: "Boites à habits ou penderie carton",
                statuses: ["non-inclus", "compris", "compris", "compris"]
            },
            {
                title: "Conditionnement des tableaux et miroirs",
                subtitle: "Bulle kraft ou carton",
                statuses: ["non-inclus", "option", "compris", "compris"]
            },
            {
                title: "Emballage et déballage des objets fragiles",
                subtitle: "Vaisselle, verreries, bibelots ...",
                statuses: ["non-inclus", "option", "compris", "compris"]
            },
            {
                title: "Démontage et remontage du mobilier",
                subtitle: "",
                statuses: ["option", "option", "compris", "compris"]
            },
            {
                title: "Emballage des objets non-fragiles",
                subtitle: "Linge, livres, ...",
                statuses: ["non-inclus", "non-inclus", "non-inclus", "compris"]
            },
            {
                title: "Décrochage des lustres et rideaux",
                subtitle: "",
                statuses: ["non-inclus", "option", "option", "compris"]
            },
        ];

        $scope.informations = {
            vous: {},
            chargement: {
                ascenseur: "non",
                // date:moment()
            },
            livraison: {
                ascenseur: "non",
                // date:moment()
            }
        }

        $scope.goToStep = function (s) {
            console.log(s)
            $scope.step = s;
            console.log($scope.step);
        }

        $scope.modal = {
            opened: false,
            title: '',
            message: '',
            notice: '',
            btnText: ''
        }

        $scope.endModal = {
            opened: false,
            title: '',
            message: '',
            notice: '',
            btnText: ''
        }

        $scope.stepSize = {
            height: $window.innerHeight - angular.element('header').height() - angular.element('nav').height()
        };


        // Récupération des datas
        $http.get(ApiEndPoint.url + "/categories").then(function success(response) {
            $scope.categories = response.data;

            $scope.categories.map(function (cat) {
                cat.image = ApiEndPoint.url + "/static/" + cat.image;
            });

            $scope.currentCategory = $scope.categories[0];
            $rootScope.$broadcast('overlay:close', {});
        }, function fail(err) {

        });

        // Permet d'ajouter un produit au panier
        $scope.addItem = function (product) {
            if (!product.quantity) product.quantity = 0;
            product.quantity++;
            $scope.calculTotal();
        }

        // Permet de supprimer un produit du panier
        $scope.removeItem = function (product) {
            if (!product.quantity) product.quantity = 0;
            if (product.quantity > 0) {
                product.quantity--;
            }
            $scope.calculTotal();
        }

        // Permet de changer de catégorie (Lors du clic sur le menu de gauche)
        $scope.selectCategory = function (category) {
            $scope.currentCategory = category
        }


        // Fonction s'assurant de gérer la taille de la fenetre meme en cas de resize
        angular.element($window).bind('resize', function () {
            $scope.stepSize = {
                height: $window.innerHeight - angular.element('header').height() - angular.element('nav').height(),
            };
            // Permet de prendre en compte les changements dans le DOM
            $scope.$digest();
        });

        // Permet de passer d'une step à l'autre
        $scope.goStep = function (step) {
            if (step == 2) {
                if (!controleTotalMinimum()) {
                    return;
                }
            }
            $scope.step = step;
        }

        $scope.closeModal = function () {
            $scope.modal.opened = false;
        }

        $scope.closeEndModal = function () {
            $scope.endModal.opened = false;
            $timeout(function () {
                window.location.href = "http://www.demenageurfinistere.com/";
            }, 0);
        }

        var controleTotalMinimum = function () {

            $scope.calculTotal();

            if ($scope.total < 10) {
                openModal(Messages["NOT_ENOUGH_WEIGHT"]);
                return false;
            }

            return true;

        };

        // Lors du clic sur le bouton Terminer (Etape 3)
        $scope.finish = function () {

            var produits = $scope.categories.filter(function (cat) {

                cat.products = cat.products.filter(function (p) {
                    return p.quantity > 0;
                });

                return cat.products.length;
            });

            var finalData = {
                products: produits,
                informations: $scope.informations,
                prestations: $scope.prestations,
                pack: $scope.selectedPack,
                source: process.env.environment,
            }

            // Envoi des informations à l'aPI
            $http.post(ApiEndPoint.url + "/contact", {"data": finalData}).then(function success() {
                openEndModal(Messages["SENDING_SUCCESS"]);
                // $timeout(function(){
                //   window.location.href = "http://www.demenageurfinistere.com/";
                // },1000);

            }, function fail() {
                openModal(Messages["SENDING_FAILED"]);
            });
        }

        // Permet de calculer le total
        $scope.calculTotal = function () {

            $scope.total = 0;

            $scope.categories.forEach(function (category) {
                category.products.forEach(function (product) {
                    if (product.quantity) {
                        $scope.total = $scope.total + (product.quantity * product.value);
                    }
                });
            });

        };

        // Vérifie si le formulaire de contact est bien renseigné
        $scope.verifierInformations = function (formIsValid) {

            if (!formIsValid) {
                $scope.modal.title = "Formulaire incomplet";
                $scope.modal.message = "Veuillez remplir les champs obligatoires";
                $scope.modal.notice = "Les champs obligatoires sont marqués d'une étoile *";
                $scope.modal.opened = true;

                $scope.highlightErrors = true;
            } else {
                $scope.step = 3;
            }

        }

        var openModal = function (modal) {
            modal.opened = true;
            $scope.modal = modal;
        }

        var openEndModal = function (endModal) {
            endModal.opened = true;
            $scope.endModal = endModal;
        }

    }]);
