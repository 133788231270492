/**
 * @ngdoc function
 * @name simulateurFrontApp.controller:OverlayCtrl
 * @description
 * # OverlayCtrl
 * Controller of the simulateurFrontApp
 */
angular.module('simulateurFrontApp')
    .controller('OverlayCtrl', ['$scope', '$rootScope', '$timeout', function ($scope, $rootScope, $timeout) {
        $scope.loading = true;
        $rootScope.$on('overlay:close', function () {
            $timeout(function () {
                angular.element('#overlay').fadeOut(2000, function () {
                    $scope.loading = false;
                });
            }, 1000);
        });
    }]);
