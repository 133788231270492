/**
 * @ngdoc overview
 * @name simulateurFrontApp
 * @description
 * # simulateurFrontApp
 *
 * Main module of the application.
 */
angular
    .module('simulateurFrontApp')
    .constant('Prestations', [
        {
            "order": 1,
            "data": "Fourniture de cartons + adhésifs",
            "value": "non"
        },
        {
            "order": 2,
            "data": "Emballage des objets non fragiles",
            "value": "non"
        },
        {
            "order": 3,
            "data": "Déballage des objets non fragiles",
            "value": "non"
        },
        {
            "order": 4,
            "data": "Emballage des objets fragiles",
            "value": "non"
        },
        {
            "order": 5,
            "data": "Déballage des objets fragiles",
            "value": "non"
        },
        {
            "order": 6,
            "data": "Transport des cartons fragiles du client",
            "value": "non"
        },
        {
            "order": 7,
            "data": "Transport des cartons non fragiles du client",
            "value": "non"
        },
        {
            "order": 8,
            "data": "Emballage des livres",
            "value": "non"
        },
        {
            "order": 9,
            "data": "Déballage des livres",
            "value": "non"
        },
        {
            "order": 10,
            "data": "Transport des cartons de livre",
            "value": "non"
        },
        {
            "order": 11,
            "data": "Démontage du mobilier",
            "value": "non"
        },
        {
            "order": 12,
            "data": "Remontage du mobilier",
            "value": "non"
        },
        {
            "order": 13,
            "data": "Emballage vêtements",
            "value": "non"
        },
        {
            "order": 14,
            "data": "Décrochage des lustres",
            "value": "non"
        },
        {
            "order": 15,
            "data": "Nettoyage",
            "value": "non"
        },
        {
            "order": 16,
            "data": "Transport voiture",
            "value": "non"
        },
        {
            "order": 17,
            "data": "Transport animaux",
            "value": "non"
        }
    ])
