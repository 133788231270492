import process, { cpuUsage } from 'process';

if (process.env.environment === 'jezequel') {
    require('../styles/theme-jezequel.scss');
} else {
    require('../styles/theme-lherrou.scss');
}

import '../styles/main.scss';

import './app.module';
import './constants/api-endpoint.constant';
import './constants/messages.constant';
import './constants/prestations.constant';

import './controllers/main.controller';
import './controllers/overlay.controller';
